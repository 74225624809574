import { tokens } from "../tokens";

export const de = {
  [tokens.common.languageChanged]: "Sprache geändert",
  [tokens.nav.aboutUs]: "Über uns",
  [tokens.nav.academy]: "Akademie",
  [tokens.nav.account]: "Konto",
  [tokens.nav.affiliate]: "Affiliate",
  [tokens.nav.agents]: "Agenten",
  [tokens.nav.analytics]: "Analytik",
  [tokens.nav.article]: "FAQ & Artikel",
  [tokens.nav.supportChats]: "Support Chats",
  [tokens.nav.emails]: "Emails",
  [tokens.nav.auth]: "Authentifizierung",
  [tokens.nav.bankProvider]: "Bankanbieter",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Marken",
  [tokens.nav.browse]: "Durchsuche",
  [tokens.nav.calendar]: "Kalender",
  [tokens.nav.call]: "Rufsystem",
  [tokens.nav.chat]: "Plaudern",
  [tokens.nav.checkout]: "Auschecken",
  [tokens.nav.concepts]: "Konzepte",
  [tokens.nav.contact]: "Kontakt",
  [tokens.nav.orderNow]: "Jetzt bestellen",
  [tokens.nav.contactUs]: "Kontakt uns",
  [tokens.nav.course]: "Kurs",
  [tokens.nav.create]: "Schaffen",
  [tokens.nav.crypto]: "Krypto",
  [tokens.nav.customerCare]: "Kundendienst",
  [tokens.nav.customers]: "Kunden",
  [tokens.nav.dashboard]: "Armaturenbrett",
  [tokens.nav.dataEntry]: "Dateneingabe",
  [tokens.nav.demo]: "DEMO",
  [tokens.nav.details]: "Einzelheiten",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Bearbeiten",
  [tokens.nav.error]: "Fehler",
  [tokens.nav.feed]: "Füttern",
  [tokens.nav.fileManager]: "Dateimanager",
  [tokens.nav.finance]: "Finanzen",
  [tokens.nav.fleet]: "Flotte",
  [tokens.nav.forgotPassword]: "Passwort Vergessen",
  [tokens.nav.home]: "Heim",
  [tokens.nav.injection]: "Listeninjektion",
  [tokens.nav.integration]: "Integration",
  [tokens.nav.internalChat]: "Plaudern",
  [tokens.nav.invoiceList]: "Rechnungen",
  [tokens.nav.jobList]: "Stellenangebote",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.lawFirms]: "Anwaltskanzleien",
  [tokens.nav.lead]: "Lead Management",
  [tokens.nav.leads]: "Führt",
  [tokens.nav.list]: "Aufführen",
  [tokens.nav.login]: "Anmeldung",
  [tokens.nav.logistics]: "Logistik",
  [tokens.nav.mail]: "E-Mail",
  [tokens.nav.management]: "Verwaltung",
  [tokens.nav.marketing]: "Marketingagenturen",
  [tokens.nav.merchant]: "Händlergebühr und Preise",
  [tokens.nav.offers]: "Bietet an",
  [tokens.nav.orderList]: "Aufträge",
  [tokens.nav.overview]: "Überblick",
  [tokens.nav.pages]: "Seiten",
  [tokens.nav.paymentService]: "Zahlungsdienstleister",
  [tokens.nav.paymentType]: "Zahlungsart",
  [tokens.nav.payment]: "Zahlungsprüfung",
  [tokens.nav.positions]: "Positionen",
  [tokens.nav.postCreate]: "Beitrag erstellen",
  [tokens.nav.postDetails]: "Beitragsdetails",
  [tokens.nav.postList]: "Beitragsliste",
  [tokens.nav.pricing]: "Preisgestaltung",
  [tokens.nav.productList]: "Produkte",
  [tokens.nav.products]: "Produkte",
  [tokens.nav.profile]: "Profil",
  [tokens.nav.record]: "Aufzeichnungen",
  [tokens.nav.register]: "Registrieren",
  [tokens.nav.reports]: "Berichte",
  [tokens.nav.resetPassword]: "Passwort Zurücksetzen",
  [tokens.nav.risk]: "Risikomanagement",
  [tokens.nav.settings]: "Einstellungen",
  [tokens.nav.socialMedia]: "Sozialen Medien",
  [tokens.nav.title]: "Titel",
  [tokens.nav.transactions]: "Transaktionen",
  [tokens.nav.walletTransactions]: "Geldbörse Transaktionen",
  [tokens.nav.validationRules]: "Validierungsaufgabe",
  [tokens.nav.verifyCode]: "Code Überprüfen",
  [tokens.nav.complianceRegulationAudit]: "Compliance",
  [tokens.nav.compliance]: "Compliance",
  [tokens.nav.agentReport]: "Agent Report",
  [tokens.nav.clientReport]: "Client Report",
  [tokens.nav.regulartoryReport]: "Regulartory Report",
  [tokens.nav.ib]: "IB-Raum",
  [tokens.nav.ibs]: "IB-Liste",
  [tokens.nav.ibRequests]: "IB-Anfragen",
};
