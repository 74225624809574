import { tokens } from "../tokens";

export const es = {
  [tokens.common.languageChanged]: "Se ha Cambiado el Idioma",
  [tokens.nav.aboutUs]: "Sobre Nosotros",
  [tokens.nav.academy]: "Academia",
  [tokens.nav.account]: "Cuenta",
  [tokens.nav.affiliate]: "Affiliate",
  [tokens.nav.agents]: "Agentes",
  [tokens.nav.analytics]: "Analítica",
  [tokens.nav.article]: "FAQ & Artículos",
  [tokens.nav.supportChats]: "Support Chats",
  [tokens.nav.emails]: "Emails",
  [tokens.nav.auth]: "Autenticación",
  [tokens.nav.bankProvider]: "Bank Provider",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Marcas",
  [tokens.nav.browse]: "Buscar",
  [tokens.nav.calendar]: "Calendario",
  [tokens.nav.call]: "sistema de Chat",
  [tokens.nav.chat]: "Charlar",
  [tokens.nav.checkout]: "Pago",
  [tokens.nav.concepts]: "Conceptos",
  [tokens.nav.contact]: "Contacto",
  [tokens.nav.orderNow]: "Ordenar ahora",
  [tokens.nav.contactUs]: "Contacto Nosotros",
  [tokens.nav.course]: "Curso",
  [tokens.nav.create]: "Crear",
  [tokens.nav.crypto]: "Cripto",
  [tokens.nav.customerCare]: "Atención al Cliente",
  [tokens.nav.customers]: "Clientes",
  [tokens.nav.dashboard]: "Panel",
  [tokens.nav.dataEntry]: "Entrada de Datos",
  [tokens.nav.demo]: "MANIFESTACIÓN",
  [tokens.nav.details]: "Detalles",
  [tokens.nav.ecommerce]: "ComercioEelectrónico",
  [tokens.nav.edit]: "Editar",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Fuente Social",
  [tokens.nav.fileManager]: "Administrador de Archivos",
  [tokens.nav.finance]: "Finanzas",
  [tokens.nav.fleet]: "Parque",
  [tokens.nav.forgotPassword]: "Recuperar Contraseña",
  [tokens.nav.home]: "Hogar",
  [tokens.nav.injection]: "Inyección de Lista",
  [tokens.nav.integration]: "Integración",
  [tokens.nav.internalChat]: "Chat Interno",
  [tokens.nav.invoiceList]: "Facturas",
  [tokens.nav.jobList]: "Listado de Trabajos",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.lawFirms]: "Bufetes de Abogados",
  [tokens.nav.lead]: "Manejo de Liderazgo",
  [tokens.nav.leads]: "Dirige",
  [tokens.nav.list]: "Lista",
  [tokens.nav.login]: "Acceso",
  [tokens.nav.logistics]: "Logística",
  [tokens.nav.mail]: "Correo",
  [tokens.nav.management]: "Gestión",
  [tokens.nav.marketing]: "Agencias de Marketing",
  [tokens.nav.merchant]: "Tarifas y Tarifas Comerciales",
  [tokens.nav.offers]: "Ofertas",
  [tokens.nav.orderList]: "Pedidos",
  [tokens.nav.overview]: "Visión general",
  [tokens.nav.pages]: "Páginas",
  [tokens.nav.paymentService]: "Proveedores de Servicios de Pago",
  [tokens.nav.paymentType]: "Tipo de Pago",
  [tokens.nav.payment]: "Auditoría de Pagos",
  [tokens.nav.positions]: "Posiciones",
  [tokens.nav.postCreate]: "Crear Articulo",
  [tokens.nav.postDetails]: "Detalles del Articulo",
  [tokens.nav.postList]: "Lista de Articulos",
  [tokens.nav.pricing]: "Precios",
  [tokens.nav.productList]: "Productos",
  [tokens.nav.products]: "Productos",
  [tokens.nav.profile]: "Perfil",
  [tokens.nav.record]: "Registros",
  [tokens.nav.register]: "Registrarse",
  [tokens.nav.reports]: "Informes",
  [tokens.nav.resetPassword]: "Restablecer Contraseña",
  [tokens.nav.risk]: "Gestión de Riesgos",
  [tokens.nav.settings]: "Ajustes",
  [tokens.nav.socialMedia]: "Redes Sociales",
  [tokens.nav.title]: "Título",
  [tokens.nav.transactions]: "Transacciones",
  [tokens.nav.walletTransactions]: "Billetera Transacciones",
  [tokens.nav.validationRules]: "Tarea de Validación",
  [tokens.nav.verifyCode]: "Verificar Código",
  [tokens.nav.wallets]: "Carteras",
  [tokens.nav.complianceRegulationAudit]: "Compliance",
  [tokens.nav.compliance]: "Compliance",
  [tokens.nav.agentReport]: "Agent Report",
  [tokens.nav.clientReport]: "Client Report",
  [tokens.nav.regulartoryReport]: "Regulartory Report",
  [tokens.nav.ib]: "Sala del IB",
  [tokens.nav.ibs]: "Lista del IB",
  [tokens.nav.ibRequests]: "Solicitudes de IB",
};
