import * as yup from "yup";
import { Button, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyEmailsApi } from "src/api/company-emails";
import toast from "react-hot-toast";
import { useEffect } from "react";
import Iconify from "src/components/iconify";

const validationSchema = yup.object({
  email: yup.string().email("Must be a valid email").required("Email is required"),
});

export const EditEmailDialog = ({ open, onClose, onGetEmails, brandId, companyId, email }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (email?.email) setValue("email", email?.email);
  }, [email]);

  const onSubmit = async (data) => {
    try {
      const request = {
        ...data,
        internal_brand_id: brandId,
        company_id: companyId,
      };
      await companyEmailsApi.updateCompanyEmail(email?.id, request);
      toast.success("Email successfully updated!");
      onClose();
      setTimeout(() => {
        onGetEmails();
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong");
      throw new Error(error);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container maxWidth="xs" sx={{ py: 3 }}>
        <Stack spacing={3}>
          <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
            <Iconify icon="iconoir:xmark" />
          </IconButton>
          <Typography fontSize={22} fontWeight={600} sx={{ textAlign: "center" }}>Update Email</Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="center" justifyContent="center" spacing={3}>
              <TextField
                label="Email"
                name="email"
                type="email"
                {...register("email")}
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                sx={{ width: 250 }}
              />

              <Button type="submit" variant="contained">Update</Button>
            </Stack>
          </form>

        </Stack>
      </Container>
    </Dialog>
  );
};
