import { useState } from "react";
import { toast } from "react-hot-toast";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from 'date-fns';

import { settingsApi } from "src/api/settings";

export const ChatReminderDialog = (props) => {
  const { open, onClose, clientId, ticketId } = props;

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());

  const [isLoading, setIsLoading]  = useState(false);
  const [description, setDescription] = useState(null);

  const handleCreateReminder = async () => {
    setIsLoading(true);
    const newTime = `${format(date, 'yyyy-MM-dd')} ${format(time, 'HH:mm')}`
    try {
      const params = {
        time: newTime,
        description,
        client_id: clientId,
      };
      if (ticketId) {
        params["ticket_id"] = ticketId;
      }
      await settingsApi.createReminder(params);
      toast.success("Reminder successfully created!");
      setTime(new Date());
      setDate(new Date());
      setDescription(null);
      onClose();
    } catch (error) {
      console.error("error:", error);
      toast.error(error?.response?.data?.message);
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
    >
      <Stack
        direction="column"
        spacing={3}
        sx={{ p: 3 }}>
        <Stack py={2}
          direction="row"
          justifyContent="center">
          <Typography variant="h6">Create Reminder</Typography>
        </Stack>
        <Stack>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography
                variant="h7"
                px={1}>
                Date
              </Typography>
              <DatePicker
                format="yyyy-MM-dd"
                label="Reminder Time"
                value={date ? new Date(date) : new Date()}
                onChange={(val) => setDate(val)}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="h7"
                px={1}>
                Time
              </Typography>
              <TimePicker
                format="h:mm a"
                label="Reminder Time"
                value={time ? new Date(time) : new Date()}
                onChange={(val) => setTime(val)}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="h7"
                px={1}>
                Description
              </Typography>
              <TextField
                label="Description"
                value={description}
                onChange={(event) => setDescription(event?.target?.value)}
              />
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 2,
                px: 3,
              }}
              gap={3}
            >
              <LoadingButton
                variant="contained"
                onClick={handleCreateReminder}
                loading={isLoading}
                disabled={isLoading}
              >
                Create
              </LoadingButton>
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
