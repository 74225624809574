import * as yup from "yup";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Iconify from "src/components/iconify";
import { ChatEmailTemplates } from "../chat/chat-email-templates";
import { QuillEditor } from "src/components/quill-editor";
import { chatApi } from "src/api/chat";
import { customersApi } from "src/api/customers";
import { useAuth } from "src/hooks/use-auth";
import { useCompanyEmails } from "../settings/emails/company-emails";
import { SelectMenu } from "src/components/customize/select-menu";

const validationSchema = yup.object({
  subject: yup.string().required("Subject is a required field"),
  content: yup
    .string()
    .required("Description is a required field")
    .test("no-html-only", "Description is a required field", (value) => {
      return (
        value === "" || !/^\s*<[^>]+>(\s*<[^>]+>\s*)*<\/[^>]+>\s*$/.test(value)
      );
    }),
});

const useCustomerInfo = (customerId) => {
  const [emailConversactionId, setEmailConversationId] = useState(undefined);

  const hanldeCustomerInfo = async () => {
    try {
      const res = await customersApi.getCustomerInfo(customerId);
      setEmailConversationId(res?.client?.email_conversation_id ?? "");
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (customerId) {
      hanldeCustomerInfo();
    }
  }, [customerId]);

  return { emailConversactionId };
};

export const CustomerCreateQuickEmail = ({ open, onClose, quickEmailInfo }) => {
  const { emailConversactionId } = useCustomerInfo(quickEmailInfo?.customerId);
  const [openEmailTemplates, setOpenEmailTemplates] = useState(false);

  const { user, company } = useAuth();

  const { emails } = useCompanyEmails(company?.id, null);

  const {
    control,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (data) => {
    try {
      const request = {
        subject: data?.subject,
        description: data?.content,
        send_email: true,
        conversation_id: emailConversactionId,
        sender_email: data?.sender_email,
      };

      await chatApi.sendMessage(request);

      toast.success("Email successfully sent!");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          error?.message ??
          "Something went wrong!"
      );
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    reset({
      subject: "",
      content: "",
      sender_email: "",
    });
  }, [open]);

  useEffect(() => {
    setTimeout(() => {
      setValue("sender_email", user?.email);
    }, 1000);
  }, [user, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <Container
          maxWidth="sm"
          sx={{ py: { xs: 3, md: 5 }, px: { xs: 2, md: 5 } }}
        >
          <Stack spacing={4}>
            <Typography
              fontSize={22}
              fontWeight={600}
              sx={{ textAlign: "center" }}
            >
              Send Quick Email
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Stack sx={{ pb: "0.5px" }} spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" px={1}>
                      Sender
                    </Typography>
                  </Stack>
                  <SelectMenu
                    control={control}
                    list={[{ label: user?.email, value: user?.email }, ...emails?.map(email => ({ value: email?.email, label: email?.email }))]}
                    name="sender_email"
                  />
                </Stack>
                <Stack sx={{ pb: "0.5px" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" px={1}>
                      Subject
                    </Typography>
                    <Tooltip title="Select from templates">
                      <IconButton
                        sx={{
                          color: "primary.main",
                        }}
                        onClick={() => setOpenEmailTemplates(true)}
                      >
                        <Iconify
                          icon="fluent:mail-template-24-regular"
                          width={24}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <TextField
                    fullWidth
                    name="subject"
                    type="text"
                    hiddenLabel
                    error={!!errors?.subject?.message}
                    helperText={errors?.subject?.message}
                    placeholder="Write a subject"
                    {...register("subject")}
                  />
                </Stack>
                <Stack
                  sx={{
                    width: 1,
                    "& .quill": {
                      transition:
                        "border-color 0.15s ease, border-width 0.15s ease",
                      borderColor:
                        errors?.content?.message?.length > 0
                          ? "error.main"
                          : "auto",
                      borderWidth: errors?.content?.message?.length > 0 ? 2 : 2,
                      "&:focus-within": {
                        borderWidth: 2,
                        borderColor:
                          errors?.content?.message?.length > 0
                            ? "error.main"
                            : "primary.main",
                      },
                    },
                    gap: 1,
                  }}
                >
                  <Typography variant="subtitle1" px={1}>
                    Content
                  </Typography>
                  <Controller
                    control={control}
                    name="content"
                    render={({ field: { value, onChange } }) => (
                      <QuillEditor
                        className="mail-template"
                        value={value}
                        onChange={onChange}
                        placeholder="Write a text content"
                        sx={[
                          { height: 250, width: 1, transition: 0.3 },
                          !!errors?.content?.message && {
                            border: "solid 4px",
                            borderColor: "error.main",
                          },
                        ]}
                      />
                    )}
                  />
                  {!!errors?.content?.message && (
                    <FormHelperText
                      sx={{ px: 2, mt: -1 }}
                      error={!!errors?.content?.message}
                    >
                      {errors?.content?.message}
                    </FormHelperText>
                  )}
                </Stack>
                <Stack
                  gap={2}
                  sx={{
                    width: { md: 1, xs: 1 },
                    px: { md: 0, xs: 12 },
                    flexDirection: "row",
                    justifyContent: { md: "flex-end", xs: "center" },
                  }}
                >
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={!emailConversactionId}
                    variant="contained"
                    type="submit"
                    sx={{
                      width: 100,
                    }}
                  >
                    Send
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    sx={{ width: 100 }}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Container>
      </Dialog>
      <ChatEmailTemplates
        open={openEmailTemplates}
        onClose={() => setOpenEmailTemplates(false)}
        brandId={quickEmailInfo?.brandId}
        onApplyTemplate={(info) => {
          setValue("subject", info?.subject);
          setValue("content", info?.description);
          clearErrors();
        }}
      />
    </>
  );
};
