import { tokens } from "../tokens";

export const ru = {
  [tokens.common.languageChanged]: "Язык изменен",
  [tokens.nav.aboutUs]: "О нас",
  [tokens.nav.academy]: "Академия",
  [tokens.nav.account]: "Аккаунт",
  [tokens.nav.affiliate]: "Партнеры",
  [tokens.nav.agents]: "Агенты",
  [tokens.nav.analytics]: "Аналитика",
  [tokens.nav.article]: "FAQ и Статьи",
  [tokens.nav.supportChats]: "Чаты поддержки",
  [tokens.nav.emails]: "Электронная почта",
  [tokens.nav.auth]: "Авторизация",
  [tokens.nav.bankProvider]: "Банковский провайдер",
  [tokens.nav.blog]: "Блог",
  [tokens.nav.brands]: "Бренды",
  [tokens.nav.browse]: "Просмотр",
  [tokens.nav.calendar]: "Календарь",
  [tokens.nav.logs]: "Логи",
  [tokens.nav.call]: "Система звонков",
  [tokens.nav.chat]: "Чат",
  [tokens.nav.checkout]: "Оформление заказа",
  [tokens.nav.concepts]: "Концепции",
  [tokens.nav.contact]: "Контакт",
  [tokens.nav.contactUs]: "Связаться с нами",
  [tokens.nav.orderNow]: "Заказать сейчас",
  [tokens.nav.course]: "Курс",
  [tokens.nav.create]: "Создать",
  [tokens.nav.crypto]: "Крипто",
  [tokens.nav.customerCare]: "Поддержка клиентов",
  [tokens.nav.customers]: "Клиенты",
  [tokens.nav.dashboard]: "Панель управления",
  [tokens.nav.dataEntry]: "Ввод данных",
  [tokens.nav.demo]: "ДЕМО",
  [tokens.nav.details]: "Детали",
  [tokens.nav.ecommerce]: "Электронная коммерция",
  [tokens.nav.edit]: "Редактировать",
  [tokens.nav.error]: "Ошибка",
  [tokens.nav.feed]: "Лента",
  [tokens.nav.fileManager]: "Файловый менеджер",
  [tokens.nav.finance]: "Финансы",
  [tokens.nav.fleet]: "Автопарк",
  [tokens.nav.forgotPassword]: "Забыли пароль",
  [tokens.nav.home]: "Главная",
  [tokens.nav.injection]: "Список инъекций",
  [tokens.nav.integration]: "Интеграция",
  [tokens.nav.internalChat]: "Внутренний чат",
  [tokens.nav.invoiceList]: "Счета",
  [tokens.nav.jobList]: "Список вакансий",
  [tokens.nav.kanban]: "Канбан",
  [tokens.nav.lawFirms]: "Юридические фирмы",
  [tokens.nav.lead]: "Управление лидами",
  [tokens.nav.leads]: "Лиды",
  [tokens.nav.list]: "Список",
  [tokens.nav.login]: "Вход",
  [tokens.nav.logistics]: "Логистика",
  [tokens.nav.mail]: "Почта",
  [tokens.nav.management]: "Управление",
  [tokens.nav.marketing]: "Маркетинговые агентства",
  [tokens.nav.merchant]: "Комиссии и тарифы",
  [tokens.nav.offers]: "Предложения",
  [tokens.nav.orderList]: "Заказы",
  [tokens.nav.overview]: "Обзор",
  [tokens.nav.pages]: "Страницы",
  [tokens.nav.paymentService]: "Платежные провайдеры",
  [tokens.nav.paymentType]: "Тип оплаты",
  [tokens.nav.payment]: "Аудит платежей",
  [tokens.nav.positions]: "Позиции",
  [tokens.nav.postCreate]: "Создать пост",
  [tokens.nav.postDetails]: "Детали поста",
  [tokens.nav.postList]: "Список постов",
  [tokens.nav.pricing]: "Цены",
  [tokens.nav.productList]: "Продукты",
  [tokens.nav.products]: "Продукты",
  [tokens.nav.profile]: "Профиль",
  [tokens.nav.record]: "Записи",
  [tokens.nav.register]: "Регистрация",
  [tokens.nav.reports]: "Отчеты",
  [tokens.nav.resetPassword]: "Сброс пароля",
  [tokens.nav.risk]: "Управление рисками",
  [tokens.nav.leaderboard]: "Таблица лидеров",
  [tokens.nav.settings]: "Настройки",
  [tokens.nav.socialMedia]: "Социальные сети",
  [tokens.nav.title]: "Заголовок",
  [tokens.nav.transactions]: "Транзакции",
  [tokens.nav.walletTransactions]: "Транзакции кошелька",
  [tokens.nav.validationRules]: "Задача валидации",
  [tokens.nav.verifyCode]: "Проверка кода",
  [tokens.nav.wallets]: "Кошельки",
  [tokens.nav.complianceRegulationAudit]: "Комплаенс",
  [tokens.nav.compliance]: "Комплаенс",
  [tokens.nav.agentReport]: "Отчет агента",
  [tokens.nav.clientReport]: "Отчет клиента",
  [tokens.nav.regulartoryReport]: "Регуляторный отчет",
  [tokens.nav.ib]: "Комната IB",
  [tokens.nav.ibs]: "Список IB",
  [tokens.nav.ibRequests]: "Запросы IB",
};
