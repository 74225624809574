import { utcToZonedTime } from "date-fns-tz";
import format from "date-fns-tz/format";

export const utcToZoned = (val, userTimezone) => {
  return val
    ? format(
      utcToZonedTime(
        new Date(val),
        userTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
      ),
      "yyyy-MM-dd HH:mm"
    )
    : "";
};
